<template>
  <div class="content">
    <ul class="content__list">
      <li
          class="content__list-item"
          data-layout-drop
          v-for="(synonymReplacement, synonymReplacementIndex) in routerData?.replacements"
          v-show="!activeFilterToggled || (activeFilterToggled && activeIds[routerData.id]?.includes(synonymReplacement.id))"
          v-bind:key="synonymReplacementIndex"
          v-on:drop="onDropSynonymReplacement($event, synonymReplacementIndex)"
          v-on:dragenter.prevent="dragEnterStyle($event)"
          v-on:dragover.prevent="dragEnterStyle($event)"
          v-on:dragleave.prevent="dragLeaveStyle($event)"
      >
        <div class="drag-tile -synonym" v-bind:class="{'-active' : activeIds[routerData.id]?.includes(synonymReplacement.id)}" data-drag-item>
          <div class="drag-tile__flag" v-if="activeIds[routerData.id]?.indexOf(synonymReplacement.id) === 0">
            <Icon name="flag" />
          </div>
          <div
              class="drag-tile__drag"
              draggable="true"
              v-on:dragstart="dragStartSynonymReplacement($event, synonymReplacementIndex)"
          >
            <Icon name="big-drag" css-class="-drag"/>
          </div>
          <div class="drag-tile__content">
            <div class="drag-tile__header">
              <title-editor
                  :value="routerData.key"
                  @edit-title="(value) => { editSynonym(value, routerData.id)}"
              />
              <button class="button edit-button" @click="deleteSynonymReplacement(synonymReplacement.id)">
                <Icon name="delete-button" css-class="-edit" />
              </button>
            </div>
            <div
                class="drag-tile__dropzone"
                data-signal-drop
                v-on:drop="onDropSignalSynonymReplacement($event, synonymId, synonymReplacement.id)"
                v-on:dragenter.prevent="dragEnterStyle($event)"
                v-on:dragover.prevent="dragEnterStyle($event)"
                v-on:dragleave.prevent="dragLeaveStyle($event)"
            >
            <span
                class="drag-tile__signal"
                v-for="(selector, index) in synonymReplacement?.selectors"
                v-bind:key="index"
                v-bind:class="{'not' : selector.startsWith('not.')}"
            >
              <button
                  v-on:click="toggleSignalInSynonymReplacement(selector, synonymId, synonymReplacement.id)"
              >
                {{selector}}
              </button>
              <button v-on:click="removeSignalFromSynonymReplacement(selector, synonymId, synonymReplacement.id)">
                <Icon name="cancel" css-class="drag-tile__signal-icon"/>
              </button>
            </span>
            </div>
            <div class="drag-tile__body">
              <SignalAutocompleterTinyMce
                  v-bind:synonym-replacement-id="synonymReplacement.id"
                  v-bind:html-text="synonymReplacement.value"
                  v-bind:signals="signalData"
                  v-bind:active="(routerData?.replacements.length - 1) === synonymReplacementIndex"
                  @change="changeSynonymReplacement"
              />
              <button class="button -edit -magic" @click="autoFillThesaurus(routerData.key, synonymReplacement.value, synonymReplacement.id)">
                <Icon name="magic-wand" css-class="-edit" />
              </button>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="content__button">
      <button class="button" @click="appendSynonymReplacement">
        <Icon name="add-button" css-class="-add" />
      </button>
    </div>
  </div>
  <Modal
      v-bind:show-modal="deletionModalOpen"
      @toggle-modal="confirmDeletion(false)"
      @submit-click="confirmDeletion(true)"
      @cancel-click="confirmDeletion(false)"
      header-text="Bestätigung"
      button-text="Löschen"
      cancel-button-text="Abbrechen"
      v-bind:centered="true"
  >
    <template v-slot:body>
      <div>
        Sind Sie sicher?
      </div>
    </template>
  </Modal>
</template>

<script>
import TitleEditor from "@/components/TitleEditor";
import Icon from "@/components/generic/Icon";
import Modal from "@/components/generic/Modal";
import SignalAutocompleterTinyMce from './SignalAutocompleterTinyMce';
import DragDrop from "@/classes/DragDrop";

export default {
  name: 'SynonymAlternativeListWidget',
  mixins: [DragDrop],
  components: {
    TitleEditor,
    Icon,
    Modal,
    SignalAutocompleterTinyMce,
  },
  props: {
    customerId: {
      type: String,
    },
    synonymId: {
      type: String,
    },
    dataHandler: {
      type: Object,
    },
    routerData: {
      type: Object,
    },
    activeIds: {
      type: Object,
    },
    activeFilterToggled : {
      type: Boolean,
    }
  },
  data() {
    return {
      draggingSortItem: null,
      deletionModalOpen: false,
      deletionId: null,
    };
  },
  computed: {
    signalData() {
      return this.dataHandler.getSignals();
    },
  },
  methods: {
    deleteSynonymReplacement(SynonymReplacementId) {
      if (this.routerData.replacements.length > 1) {
        this.dataHandler?.deleteSynonymReplacement(this.synonymId, SynonymReplacementId);
      } else {
        this.deletionId = SynonymReplacementId;
        this.deletionModalOpen = true;
      }
    },
    confirmDeletion(response) {
      if (response) {
        this.dataHandler?.deleteSynonymReplacement(this.synonymId, this.deletionId);
      }
      this.deletionId = null;
      this.deletionModalOpen = false;
    },
    editSynonym(value, id) {
      this.dataHandler?.editSynonym(value, id);
    },
    appendSynonymReplacement() {
      this.dataHandler?.appendSynonymReplacement(this.routerData.id);
    },
    changeSynonymReplacement(synonymReplacementId, content) {
      this.dataHandler?.changeSynonymReplacement(this.routerData.id, synonymReplacementId, content);
    },
    toggleSignalInSynonymReplacement(signal, synonymId, synonymReplacementId) {
      this.dataHandler?.toggleSignalInSynonymReplacement(signal, synonymId, synonymReplacementId);
    },
    removeSignalFromSynonymReplacement(signal, synonymId, synonymReplacementId) {
      this.dataHandler?.removeSignalFromSynonymReplacement(signal, synonymId, synonymReplacementId);
    },
    async autoFillThesaurus(key, oldValue, synonymReplacementId) {
      const suggestionsArray = await this.dataHandler.askThesaurus(key);
      const oldArray = oldValue.split(';')
      const trimmedArray = oldArray.map(str => str.trim());
      const filteredArray = trimmedArray.filter(element => {
        return element !== '';
      });
      const newArray = filteredArray.concat(suggestionsArray);
      const uniqueArray = [...new Set(newArray)];
      const newValue = uniqueArray.join('; ');
      const finalString = `${newValue};`
      if (oldValue !== finalString) {
        this.changeSynonymReplacement(synonymReplacementId, finalString);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>