<template>
  <div v-bind:id="`signal-autocompleter-tiny-mce-${synonymReplacementId}`"
       v-bind:class="[`signal-autocompleter-tiny-mce signal-autocompleter-tiny-mce-${synonymReplacementId}`]"
       v-html="htmlText">
  </div>
</template>
<script>
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme';
import 'tinymce/icons/default/icons';
import 'tinymce/plugins/noneditable/';
import 'tinymce/plugins/lists/';

export default {
  name: 'SignalAutocompleterTinyMce',
  emits:['change'],
  props: {
    htmlText: {
      type: String,
    },
    synonymReplacementId: {
      type: Number,
    },
    signals: {
      type: Array,
    },
    active: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      content: this.htmlText,
      filteredSignals: [],
    }
  },
  methods: {
    autocompleteList(array) {
      if (array && array.length > 0) {
        array.forEach(signal => {
          if (signal.children.length > 0) {
            this.autocompleteList(signal.children);
          }
          this.filteredSignals.push({text: signal.id, value: signal.id});
        })
      }
    },
    initRTE() {
      tinymce.init({
        id: `signal-autocompleter-tiny-mce-${this.synonymReplacementId}`,
        selector: `#signal-autocompleter-tiny-mce-${this.synonymReplacementId}`,
        valid_elements : "span[class]",
        inline: true,
        menubar: false,
        resize: false,
        toolbar: false,
        entity_encoding : "raw",
        font_css: 'tiny-mce-styles.css',
        plugins: 'noneditable lists',
        noneditable_noneditable_class: 'signal',
        content_style: ".signal { color: #69A0D3; }",
        setup: (editor) => {
          this.editor = editor;
          /**
           * fired when autocomplete item is selected.
           * inserts selected value wrapped in signal-span
           * @param autocompleteApi
           * @param rng
           * @param value
           */
          const onAutoCompleteAction = (autocompleteApi, rng, value) => {
            editor.selection.setRng(rng);
            editor.insertContent(`<span class="signal">${value}</span>`);
            autocompleteApi.hide();
          };

          /**
           * get matched autocomplete search.
           * sorts filtered signal list bei length.
           * filters inserted matches in uppercase.
           * @param pattern
           * @returns {*[]}
           */
          const getMatchedChars = (pattern) => {
            if(this.filteredSignals.length) {
              return this.filteredSignals.sort((a, b) => {
                // sort alphabetically
                if(a.value < b.value) { return -1; }
                if(a.value > b.value) { return 1; }
                return 0;
              }).sort((one, other) => {
                //sort by signal length
                return one.value.split('.').length - other.value.split('.').length;
              }).filter((char) => {
                //compare with uppercase input
                return char.text.indexOf(pattern.toUpperCase()) !== -1;
              });
            }
            return [];
          };

          /**
           * An autocompleter that allows you to insert signals
           */
          editor.ui.registry.addAutocompleter('autocomplete', {
            ch: '@',
            minChars: 0,
            columns: 1,
            onAction: onAutoCompleteAction,
            fetch: function (pattern) {
              return new tinymce.util.Promise( (resolve) => {
                const list = getMatchedChars(pattern);
                let results = [];
                if(list.length) {
                  //return result
                  results = list.map((char) => {
                    return { type: 'cardcontainer', value: char.value, text: char.text,}
                  });
                } else {
                  // return error message
                  results = [{ type: 'cardcontainer', value: '', text: 'no signals found',}];
                }
                resolve(results);
              });
            }
          });

          /**
           * on blur actions
           */
          editor.on('blur', () => {
            this.content = editor.getContent();
            if (this.content !== this.htmlText) {
              this.$emit('change', this.synonymReplacementId, this.content);
            }
          });

          if (this.active) {
            setTimeout(() => {
              editor?.selection.select(editor.getBody(), true);
              editor?.selection.collapse(false);
            }, 200);
          }
        }
      });
    },
  },
  mounted() {
    this.autocompleteList(this.signals);
    this.initRTE();
  },
  unmounted() {
    this.editor?.destroy();
  }
}
</script>

<style lang="scss" scoped>
.tox-tinymce-inline {
  z-index: 1000;
}
.signal-autocompleter-tiny-mce {
  width: 100%;
  font-size: rem(14px);
  line-height: 1.9;
  flex-grow: 1;
  outline: none;
  margin-right: rem(10px);
  overflow: auto;
}
</style>